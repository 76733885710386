import { useCallback, useEffect, useMemo, useState } from 'react';
import { translationService } from '../services/translationsService';

const useReusableContent = () => {
  const [content, setContent] = useState(null);

  const fallback = useMemo(() => content?.fallback, [content]);

  const loadContent = useCallback(async () => {
    const content = await translationService.getReusableContent();

    setContent(content);
  }, []);

  useEffect(() => {
    loadContent();
  }, [loadContent]);

  const getReusableContent = (key: string): string => {
    if (content === null || !(key in content)) {
      return '';
    }

    return content[key];
  };

  return { getReusableContent, fallback };
};

export { useReusableContent };
