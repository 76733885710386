import { FC, Suspense } from 'react';
import { appHelper } from '../helpers/appHelper';

export type rtlPolicy = 'default' | 'ignoreGlobalPage';

type WithRtlProps = {
  lazyElement: () => JSX.Element;
  policy?: rtlPolicy;
};

const WithRtl: FC<WithRtlProps> = ({ lazyElement, policy }) => {
  let isRtl = appHelper.isGlobalPageRtl;
  if (policy && policy === 'ignoreGlobalPage') {
    isRtl = appHelper.isRtl;
  }

  return (
    <Suspense fallback={<></>}>
      <>{isRtl && lazyElement}</>
    </Suspense>
  );
};

export default WithRtl;
