import { useLayoutEffect, useState } from 'react';
import { debounce } from '../helpers/debounce';

export const useIsMobileShared = (width: number, noZoomLogic = false) => {
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    const resize = () => {
      const screenWidth = window.screen.width;
      const zoomLevel = isDeviceEmulation() ? 1 : window.devicePixelRatio;

      const effectiveWidth = noZoomLogic ? screenWidth : screenWidth / zoomLevel;

      const _width =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      setIsMobile(() => {
        return effectiveWidth < width || _width < width;
      });
    };
    resize();
    window.addEventListener('resize', debounce(resize));

    return window.removeEventListener('resize', resize);
  }, []);

  return isMobile;
};

export const useIsWithinWidthRange = (minWidth: number, maxWidth: number) => {
  const [isWithinRange, setIsWithinRange] = useState(false);

  useLayoutEffect(() => {
    const resize = () => {
      const screenWidth = window.screen.width;
      const zoomLevel = isDeviceEmulation() ? 1 : window.devicePixelRatio;

      const effectiveWidth = screenWidth / zoomLevel;
      const _width =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      setIsWithinRange(() => {
        return (
          (_width >= minWidth || effectiveWidth >= minWidth) &&
          (effectiveWidth <= maxWidth || _width <= maxWidth)
        );
      });
    };
    resize();
    window.addEventListener('resize', debounce(resize));

    return () => window.removeEventListener('resize', resize);
  }, [minWidth, maxWidth]);

  return isWithinRange;
};

const isDeviceEmulation = () => {
  const userAgent = window.navigator.userAgent;
  const zoomLevel = window.devicePixelRatio;
  return /Mobi|Android/i.test(userAgent) && zoomLevel > 1;
};
