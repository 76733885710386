import { forwardRef, useState } from 'react';
import './styles/ZimAlert.scss';
import { Alert } from 'react-bootstrap';

export type ZimAlertProps = {
  show: boolean;
  title?: string | '';
  showHeader?: boolean | false;
  bodyText: string;
  isHtml?: boolean;
  variant: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  closeHandler?: () => void | null;
};

const ZimAlert = forwardRef<HTMLDivElement, ZimAlertProps>(
  ({ show, title, showHeader, bodyText, isHtml, variant, closeHandler }, ref) => {
    const [visible, setVisible] = useState(show);

    const Close = () => {
      setVisible(false);
      if (closeHandler) {
        closeHandler();
      }
    };

    return (
      <Alert className="zim-alert" show={visible} variant={variant} onClose={Close} dismissible>
        {showHeader && <Alert.Heading>{title}</Alert.Heading>}
        {!isHtml && <p>{bodyText}</p>}
        {isHtml && (
          <div
            dangerouslySetInnerHTML={{
              __html: bodyText
            }}></div>
        )}
      </Alert>
    );
  }
);

export default ZimAlert;
