import { env } from '../environments/environment';
import { ApiResponse } from '../types/shared/common';
import { postData } from './crud';

class NewsletterService {
  subscribe = async (
    email: string,
    consentToEmail: boolean,
    privacyPolicy: boolean,
    editPref: boolean,
    captchaCode: string,
    subscribeToAll: boolean,
    listsId: string,
    sourceUrl: string
  ): Promise<ApiResponse> => {
    const data = await postData<ApiResponse>(`${env.fixed.newsletter.subscribe}`, {
      email: email,
      consentToEmail: consentToEmail,
      privacyPolicy: privacyPolicy,
      editPreferences: editPref,
      captcha: captchaCode,
      subscribeToAll: subscribeToAll,
      listsId: listsId,
      sourceUrl: sourceUrl
    });

    return data;
  };
  subscribeEmployeeData = async (
    email: string,
    firstName: string,
    lastName: string,
    companyName: string,
    countryCode: string,
    phone: string,
    prefix: string,
    consentToEmail: boolean,
    privacyPolicy: boolean,
    captchaCode: string,
    subscribeToAll: boolean,
    listsId: string,
    sourceUrl: string
  ): Promise<ApiResponse> => {
    const data = await postData<ApiResponse>(`${env.fixed.newsletter.subscribeEmployeeData}`, {
      email: email,
      firstName: firstName,
      lastName: lastName,
      companyName: companyName,
      countryCode: countryCode,
      phone: phone,
      prefix: prefix,
      consentToEmail: consentToEmail,
      privacyPolicy: privacyPolicy,
      captcha: captchaCode,
      subscribeToAll: subscribeToAll,
      listsId: listsId,
      sourceUrl: sourceUrl
    });

    return data;
  };

  subscribePersonalData = async (
    email: string,
    firstName: string,
    lastName: string,
    consentToEmail: boolean,
    privacyPolicy: boolean,
    captchaCode: string,
    subscribeToAll: boolean,
    listsId: string,
    sourceUrl: string
  ): Promise<ApiResponse> => {
    const data = await postData<ApiResponse>(`${env.fixed.newsletter.subscribePersonalData}`, {
      email: email,
      firstName: firstName,
      lastName: lastName,
      consentToEmail: consentToEmail,
      privacyPolicy: privacyPolicy,
      captcha: captchaCode,
      subscribeToAll: subscribeToAll,
      listsId: listsId,
      sourceUrl: sourceUrl
    });

    return data;
  };
  subscribeNewsletter = async (token: string): Promise<ApiResponse> => {
    const data = await postData<ApiResponse>(`${env.fixed.newsletter.subscribeNewsletter}`, {
      token: token
    });

    return data;
  };
}

export const newsletterService = new NewsletterService();
