import { useEffect, useState, lazy, useMemo, useRef } from 'react';
import './styles/Footer.scss';
import WithRtl from '../../shared/WithRtl';
import { useIsMobileNewUi } from '../../hooks/useIsMobileNewUi';
import { Accordion } from 'react-bootstrap';
import AccordionItem from 'react-bootstrap/esm/AccordionItem';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import AccordionBody from 'react-bootstrap/esm/AccordionBody';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { appHelper } from '../../helpers/appHelper';
import LazyLoad from 'react-lazy-load';
import { Scope } from '../../types/dto/Scope';
import { NewsletterEmailSignupResult } from '../../types/dto/Newsletter';
import { ApiResponse } from '../../types/shared/common';
import { newsletterService } from '../../services/newsletterService';
import ZimAlert from '../../shared/ZimAlert/ZimAlert';
import { useReusableContent } from '../../hooks/useReusableContent';
import classNames from 'classnames';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { translationService } from '../../services/translationsService';
import { stringExtensions } from '../../helpers/stringExtensions';
import classnames from 'classnames';
import cleanInput from '../../assets/images/clean-input.png';
import { Form } from 'react-bootstrap';
import { useIsMobileShared } from '../../hooks/useIsMobileShared';

export type FooterNewsletter = {
  subscribeToAll: boolean;
  listIds: string;
  hideForm: boolean;
};

export interface FooterNewSettingsModel {
  SubscribeToAll: boolean;
  ListIds: string;
  HideForm: boolean;
  Items: FooterNewModel[];
}

export interface FooterNewModel {
  Category: string;
  Items: FooterItem[];
}

export interface FooterItem {
  Name: string;
  Values: ObjectValue[];
}

export interface ObjectValue {
  Title: string;
  Link: string;
  Icon: string;
  AltName: string;
  IconMobile: string;
}

const RTL = lazy(() => import('./FooterRtl'));

function Footer() {
  const { t } = useTranslation();
  const translations = useMemo(() => {
    return {
      subscribe: t('footer.Subscribe'),
      emailPlaceHolder: t('footer.EmailPlaceholder'),
      emailFormat: t('forms.EmailFormat'),
      email: t('email'),
      requiredFormat: t('forms.RequiredFormat'),
      error: t('common.Error'),
      commonError: t('common'),
      captchaError: t('forms.CaptchaError'),
      captchaLabel: t('forms.Captcha'),
      lessCaptchaError: t('forms.LessCaptchaError'),
      description: t('newsletter.Description')
    };
  }, [t]);
  const isMobile = useIsMobileShared(1280);
  const [isGlobalLoaded, setIsGlobalLoaded] = useState(false);
  const [footerNewsletter, setFooterNewsletter] = useState<FooterNewsletter>({
    subscribeToAll: false,
    listIds: '',
    hideForm: true
  });
  const [cookies, setCookie] = useCookies(['language', 'country']);
  const [scope, setScope] = useState<Scope<NewsletterEmailSignupResult>>(appHelper.getDefaultScope);
  const [captchaError, setCaptchaError] = useState<boolean>(false);
  const [token, setToken] = useState('');
  const captchaRef = useRef<HCaptcha>(null);
  const captchaRefMobile = useRef<HCaptcha>(null);
  const { getReusableContent } = useReusableContent();
  const successMessage = getReusableContent('newsletter.FooterSignupSuccess');
  const enableCaptcha = appHelper.geEnableCaptcha;
  const lessCaptcha = appHelper.geLessCaptcha;
  const [dataRes, setDataRes] = useState<FooterNewSettingsModel>();
  const footerLinks = dataRes?.Items?.find((c) => c.Category === 'FooterLinks');
  const footerSocialItems = dataRes?.Items?.find((c) => c.Category === 'FooterSocialItems');
  const footerText = dataRes?.Items?.find((c) => c.Category === 'FooterText');
  const footerLogo = dataRes?.Items?.find((c) => c.Category === 'FooterLogo');
  const footerUsefulLinks = dataRes?.Items?.find((c) => c.Category === 'FooterUsefulLinks');
  const footerAppIcons = dataRes?.Items?.find((c) => c.Category === 'FooterAppIcons');
  const footerCopyRight = dataRes?.Items?.find((c) => c.Category === 'FooterCopyRight');
  const [errorState, setErrorState] = useState({
    footerEmail: false
  });
  const [isFormValid, setFormValid] = useState(false);
  const [successClosed, setSuccessClosed] = useState<boolean>(false);
  const [isConsToEmailChecked, setIsConsToEmailChecked] = useState<boolean>(false);
  const { innerWidth } = window;
  useEffect(() => {
    initCookies();
    async function fetchData() {
      try {
        const footerDiv = document.getElementById('footerReact');
        const footerDivJson = footerDiv.getAttribute('data-footerReact');
        const response = JSON.parse(footerDivJson);
        setDataRes(response);
        const newsletterItem: FooterNewsletter = {
          subscribeToAll: response.subscribeToAll,
          listIds: response.listIds,
          hideForm: response.hideForm
        };
        setFooterNewsletter(newsletterItem);
      } catch (error) {
        console.error('An error occurred while fetching the data:', error);
      }
    }
    fetchData();
  }, []);

  const testCaptcha = (value: string): boolean => {
    if (!enableCaptcha) {
      return true;
    }
    const result = !stringExtensions.isEmptyOrSpaces(token);
    if (!result) {
      setCaptchaError(true);
    }
    return result;
  };

  const [focusedField, setFocusedField] = useState(null);
  const [form, setForm] = useState({
    footerEmail: '',
    captchaCode: '',
    consToEmail: ''
  });

  const validationRules = {
    footerEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setForm((prevForm) => {
      const updatedForm = { ...prevForm, [name]: value };

      setErrorState((prevErrors) => {
        const newErrors = { ...prevErrors };
        const isValid = validationRules[name].test(value);
        newErrors[name] = !isValid;

        const allFieldsFilledAndValid = requiredFields.every((fieldName) => {
          return updatedForm[fieldName].trim() && !newErrors[fieldName];
        });
        setFormValid(allFieldsFilledAndValid);
        return newErrors;
      });

      return updatedForm;
    });
  };

  const cookieDomain = useMemo(() => {
    const domain = appHelper.getDomainWithoutSubdomain().split('/')[0];
    if (domain === 'localhost') {
      return domain;
    }
    return `.${domain}`;
  }, []);

  const initCookies = () => {
    if (!cookies.country) {
      setCookie('country', 'US', {
        path: '/',
        expires: appHelper.cookiesExpiryDate(),
        secure: true,
        domain: cookieDomain
      });
    }
    if (!cookies.language) {
      setCookie('language', 'en-US', {
        path: '/',
        expires: appHelper.cookiesExpiryDate(),
        secure: true,
        domain: cookieDomain
      });
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (lessCaptcha && enableCaptcha) {
      if (isMobile) {
        captchaRefMobile?.current?.execute();
      } else {
        captchaRef?.current?.execute();
      }
    } else {
      if (
        form.footerEmail?.length > 0 &&
        appHelper.getEmailPattern().test(form.footerEmail) &&
        testCaptcha(form.captchaCode) &&
        form.consToEmail
      ) {
        const sourceUrl = window.location.href;
        subscribe(
          form.footerEmail,
          form.consToEmail === 'true',
          true,
          false,
          token,
          footerNewsletter.subscribeToAll,
          footerNewsletter.listIds,
          sourceUrl
        );
      }
    }
  };

  useEffect(() => {
    if (lessCaptcha && token) {
      if (
        form.footerEmail?.length > 0 &&
        appHelper.getEmailPattern().test(form.footerEmail) &&
        testCaptcha(form.captchaCode) &&
        form.consToEmail
      ) {
        const sourceUrl = window.location.href;
        const lessToken = `${token}${appHelper.getLessPattern()}`;
        subscribe(
          form.footerEmail,
          form.consToEmail === 'true',
          true,
          false,
          lessToken,
          footerNewsletter.subscribeToAll,
          footerNewsletter.listIds,
          sourceUrl
        );
      }
    }
  }, [token]);

  const subscribe = async (
    email: string,
    consentToEmail: boolean,
    privacyPolicy: boolean,
    editPref: boolean,
    captchaCode: string,
    subscribeToAll: boolean,
    listsId: string,
    sourceUrl: string
  ) => {
    setScope((prev) => {
      return { ...prev, isLoading: true, isResponse: false };
    });

    let errorMessage = '';
    let subscribeResult: ApiResponse = null;

    try {
      subscribeResult = await newsletterService.subscribe(
        email,
        consentToEmail,
        privacyPolicy,
        editPref,
        captchaCode,
        subscribeToAll,
        listsId,
        sourceUrl
      );
    } catch (error) {
      if (error?.response?.status === 400) {
        errorMessage = lessCaptcha ? translations.lessCaptchaError : translations.captchaError;
      } else {
        errorMessage = translations.commonError;
      }
      captchaRef?.current?.resetCaptcha();
      captchaRefMobile?.current?.resetCaptcha();
    }

    if (subscribeResult?.isSuccess) {
      setScope((prev) => {
        return {
          ...prev,
          result: subscribeResult,
          isLoading: false,
          isResponse: true
        };
      });
    } else {
      errorMessage = errorMessage ? errorMessage : subscribeResult?.message;
      if (!errorMessage) {
        errorMessage = translations.error;
      }

      subscribeResult = subscribeResult ?? { isSuccess: false, message: null };

      setScope((prev) => {
        return {
          ...prev,
          isLoading: false,
          result: subscribeResult,
          errorMessage,
          isResponse: true
        };
      });

      if (!subscribeResult?.isSuccess) {
        captchaRef?.current?.resetCaptcha();
        captchaRefMobile?.current?.resetCaptcha();
      }
    }
  };

  const closeHandler = () => {
    setSuccessClosed(true);
  };

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    const trimmedValue = value.trim();
    setFocusedField(null);

    setErrorState((prevErrors) => {
      let isValid = true;
      const newErrors = { ...prevErrors };

      if (trimmedValue === '' && isFieldRequired(name)) {
        isValid = false;
      }
      newErrors[name] = !isValid;

      setForm((prevForm) => {
        const allValid = checkAllRequiredFieldsValidity(newErrors, prevForm);
        setFormValid(allValid);
        return prevForm;
      });

      return newErrors;
    });
  };
  const requiredFields = ['footerEmail', 'consToEmail'];
  const isFieldRequired = (fieldName) => {
    return requiredFields.includes(fieldName);
  };

  const checkAllRequiredFieldsValidity = (errors, form) => {
    return requiredFields.every((fieldName) => {
      return !errors[fieldName] && form[fieldName].trim();
    });
  };

  const clearInputField = (fieldName) => {
    setForm((prevForm) => ({
      ...prevForm,
      [fieldName]: ''
    }));
    setErrorState((prevErrorState) => ({
      ...prevErrorState,
      [fieldName]: false
    }));
    setFormValid(false);
  };

  const captchaCulture: string = translationService.getCulture()?.split('-')[0];

  const captchaDesktopParams = appHelper.getCaptchaParams(
    captchaCulture,
    innerWidth,
    setToken,
    lessCaptcha,
    'ZimCaptchaIdFooterDesktop'
  );

  const captchaMobileParams = appHelper.getCaptchaParams(
    captchaCulture,
    innerWidth,
    setToken,
    lessCaptcha,
    'ZimCaptchaIdFooterMobile'
  );

  const onConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setForm((prevForm) => {
      const updatedForm = { ...prevForm, [name]: checked === true ? 'true' : '' };
      setErrorState((prevErrors) => {
        const newErrors = { ...prevErrors };
        const isValid = checked === true;
        newErrors[name] = !isValid;

        const allFieldsFilledAndValid = requiredFields.every((fieldName) => {
          return updatedForm[fieldName].trim() && !newErrors[fieldName];
        });
        setFormValid(allFieldsFilledAndValid);
        return newErrors;
      });

      return updatedForm;
    });
    setIsConsToEmailChecked(checked);
  };

  const renderByDevice = () => {
    if (!isMobile) {
      return (
        <footer className="footer_container">
          <section className="upper_section">
            <div className="logo_container">
              <img
                alt="logo"
                className="logo"
                src={`${footerLogo?.Items?.[0]?.Values?.[0]?.Title}` || ''}
              />
            </div>
            <div className="links">
              {footerLinks &&
                footerLinks.Items.length > 0 &&
                footerLinks.Items.map((section, index) => (
                  <div key={index} className="link_colom">
                    <h4 className="link_header">{section.Name}</h4>
                    <ul className="link_item">
                      {section.Values.map((item, itemIndex) => (
                        <li key={itemIndex} className="link_li">
                          <a className="link" href={item.Link}>
                            {item.Title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </section>
          <section
            className={classnames('mid_section', {
              'al-end': scope.isResponse && successClosed
            })}>
            {!footerNewsletter?.hideForm && (
              <>
                {!scope.result?.isSuccess && (
                  <form autoComplete="off" className="footer-newsletter" onSubmit={onSubmit}>
                    <fieldset className="input_set">
                      <label>{translations.subscribe}</label>
                      <div className="united_actions">
                        <div className="form-rq-control-container">
                          <label
                            className={
                              form.footerEmail || focusedField === 'footerEmail'
                                ? 'label-above-input'
                                : 'label-in-input'
                            }
                            htmlFor="footerEmail">
                            {form.footerEmail || focusedField === 'footerEmail'
                              ? translations.email
                              : translations.emailPlaceHolder}
                          </label>
                          <input
                            id="footerEmail"
                            type="text"
                            className={`form-rq-control ${
                              errorState.footerEmail && form.footerEmail ? 'error-input' : ''
                            }`}
                            onChange={handleInputChange}
                            name="footerEmail"
                            value={form.footerEmail}
                            onBlur={handleBlur}
                            onFocus={() => handleFocus('footerEmail')}
                          />
                          {errorState.footerEmail &&
                            focusedField == 'footerEmail' &&
                            form.footerEmail.length > 0 && (
                              <div className="error-message">
                                <div className="error-field-text">{translations.emailFormat}</div>
                              </div>
                            )}
                          {form.footerEmail.length > 0 && (
                            <img
                              src={cleanInput}
                              onClick={() => clearInputField('footerEmail')}
                              className="clean-input"
                              alt="Clean Input"
                            />
                          )}
                        </div>
                        <button
                          id="arrowBtn"
                          aria-label="Submit button"
                          type="submit"
                          disabled={!isFormValid}
                          className={classNames('', {
                            disabled: !isFormValid
                          })}></button>
                      </div>
                      <div className="consent-row row g-0">
                        <div className="col-1">
                          <Form.Check
                            type="checkbox"
                            id="footerConsCheck"
                            name="consToEmail"
                            checked={isConsToEmailChecked}
                            onChange={onConsentChange}
                            className="c-check"
                          />
                        </div>
                        <div className="col-11 c-desc">{translations.description}</div>
                      </div>
                    </fieldset>
                    {enableCaptcha && (
                      <>
                        <div className="form-group captcha-box row">
                          <div className="col-sm-5 col-md-5 col-12">
                            {!lessCaptcha && (
                              <label>
                                {translations.captchaLabel}
                                <span className="umbracoForms-Indicator check-box-indicator ms-1">
                                  *
                                </span>
                              </label>
                            )}
                          </div>
                          <div className="col-sm-7 col-md-11 col-12">
                            <HCaptcha {...captchaDesktopParams} ref={captchaRef} />
                            {captchaError && (
                              <span role="alert" className="error-msg">
                                {lessCaptcha
                                  ? translations.lessCaptchaError
                                  : translations.captchaError}
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {scope.isResponse && !scope.result?.isSuccess && (
                      <ZimAlert show={true} variant="danger" bodyText={scope.errorMessage} />
                    )}
                  </form>
                )}
                {scope.isResponse && scope.result?.isSuccess && (
                  <ZimAlert
                    show={true}
                    variant="success"
                    isHtml={true}
                    bodyText={successMessage}
                    closeHandler={closeHandler}
                  />
                )}
              </>
            )}
            <div className="img_buttons">
              {footerAppIcons?.Items?.map((item, index) =>
                item?.Values?.map((value, valueIndex) =>
                  value?.Title && value?.Icon && value?.Link ? (
                    <a
                      href={value?.Link}
                      rel="noopener noreferrer"
                      key={valueIndex}
                      aria-labelledby={`downloadApp-${index + 1}`}>
                      <button
                        aria-label={value?.Title + '' + index}
                        id={`downloadApp-${index + 1}`}
                        style={{
                          background: `url(${value?.Icon})`
                        }}></button>
                    </a>
                  ) : null
                )
              )}
            </div>
          </section>
          <section className="lower_section">
            <div className="links">
              <div className="static-text">© 2018 ZIM Integrated Shipping Services Ltd.</div>
              {footerUsefulLinks?.Items?.map((item, index) =>
                item?.Values?.map((value, valueIndex) =>
                  value?.Title && value?.Link ? (
                    <li key={`${index}-${valueIndex}`}>
                      <a href={value?.Link} aria-label={value?.Title} rel="noopener noreferrer">
                        {value?.Title}
                      </a>
                    </li>
                  ) : null
                )
              )}
            </div>
            <div className="icon_links">
              {footerSocialItems?.Items?.map((item) =>
                item?.Values?.map((value) =>
                  value?.Link && value?.Icon && value?.AltName ? (
                    <a
                      href={value?.Link}
                      target="_blank"
                      rel="noreferrer"
                      aria-label={value?.Title}
                      key={value?.Title}>
                      <img className="icon_img" src={`${value?.Icon}` || ''} alt={value?.AltName} />
                    </a>
                  ) : null
                )
              )}
            </div>
          </section>
          <section
            className="white_bottom"
            dangerouslySetInnerHTML={{ __html: footerText?.Items?.[0]?.Values?.[0]?.Title || '' }}
          />
        </footer>
      );
    }
    return (
      <footer className="footer_mobile_container d-print-none">
        <img
          alt="logo_mobile"
          className="logo_mobile"
          src={
            `
          ${footerLogo?.Items?.[0]?.Values?.[0]?.Title}` || ''
          }
        />
        <section className="accordion-section">
          <Accordion className="accordion-container">
            {footerLinks &&
              footerLinks.Items.length > 0 &&
              footerLinks.Items.map((section, index) => (
                <AccordionItem eventKey={index.toString()} key={index}>
                  <AccordionHeader>{section.Name}</AccordionHeader>
                  <AccordionBody>
                    <ul>
                      {section.Values.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          <a href={item.Link}>{item.Title}</a>
                        </li>
                      ))}
                    </ul>
                  </AccordionBody>
                </AccordionItem>
              ))}
          </Accordion>
        </section>
        {!footerNewsletter?.hideForm && (
          <>
            {!scope.result?.isSuccess && (
              <form autoComplete="off" className="footer-newsletter" onSubmit={onSubmit}>
                <fieldset className="input_set">
                  <label>{translations.subscribe}</label>
                  <div className="united_actions">
                    <div className="form-rq-control-container">
                      <label
                        className={
                          form.footerEmail || focusedField === 'footerEmail'
                            ? 'label-above-input'
                            : 'label-in-input'
                        }
                        htmlFor="footerEmail">
                        {form.footerEmail || focusedField === 'footerEmail'
                          ? translations.email
                          : translations.emailPlaceHolder}
                      </label>
                      <input
                        id="footerEmail"
                        type="text"
                        className={`form-rq-control ${
                          errorState.footerEmail && form.footerEmail ? 'error-input' : ''
                        }`}
                        onChange={handleInputChange}
                        name="footerEmail"
                        value={form.footerEmail}
                        onBlur={handleBlur}
                        onFocus={() => handleFocus('footerEmail')}
                      />
                      {errorState.footerEmail &&
                        focusedField == 'footerEmail' &&
                        form.footerEmail.length > 0 && (
                          <div className="error-message">
                            <div className="error-field-text">{translations.emailFormat}</div>
                          </div>
                        )}
                      {form.footerEmail.length > 0 && (
                        <img
                          src={cleanInput}
                          onClick={() => clearInputField('footerEmail')}
                          className="clean-input"
                          alt="Clean Input"
                        />
                      )}
                    </div>
                    <button
                      type="submit"
                      id="arrowBtn"
                      aria-label="Submit button"
                      disabled={!isFormValid}
                      className={classNames('', {
                        disabled: !isFormValid
                      })}></button>
                  </div>
                  <div className="consent-row row g-0">
                    <div className="col-1">
                      <Form.Check
                        type="checkbox"
                        id="footerConsCheck"
                        name="consToEmail"
                        checked={isConsToEmailChecked}
                        onChange={onConsentChange}
                        className="c-check"
                      />
                    </div>
                    <div className="col-11 c-desc">{translations.description}</div>
                  </div>
                </fieldset>
                {enableCaptcha && (
                  <>
                    <div className="form-group captcha-box row">
                      <div className="col-sm-5 col-md-5 col-12">
                        {!lessCaptcha && (
                          <label>
                            {translations.captchaLabel}
                            <span className="umbracoForms-Indicator check-box-indicator ms-1">
                              *
                            </span>
                          </label>
                        )}
                      </div>
                      <div className="col-md-11 col-12">
                        <HCaptcha {...captchaMobileParams} ref={captchaRefMobile} />
                        {captchaError && (
                          <span role="alert" className="error-msg">
                            {lessCaptcha
                              ? translations.lessCaptchaError
                              : translations.captchaError}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {scope.isResponse && !scope.result?.isSuccess && (
                  <ZimAlert show={true} variant="danger" bodyText={scope.errorMessage} />
                )}
              </form>
            )}
            {scope.isResponse && scope.result?.isSuccess && (
              <ZimAlert show={true} variant="success" isHtml={true} bodyText={successMessage} />
            )}
          </>
        )}
        <div className="img_buttons">
          {footerAppIcons?.Items?.map((item, index) =>
            item?.Values?.map((value, valueIndex) =>
              value?.Title && value?.Icon && value?.Link ? (
                <a href={value?.Link} rel="noopener noreferrer" key={valueIndex}>
                  <button
                    aria-label={value?.Title + '' + index}
                    id={`downloadApp-${index + 1}`}
                    style={{
                      background: `url(${value?.Icon})`
                    }}></button>
                </a>
              ) : null
            )
          )}
        </div>
        <section className="footer-useful-links">
          <div className="static-text">© 2018 ZIM Integrated Shipping Services Ltd.</div>
          <ul>
            {footerUsefulLinks?.Items?.map((item, index) =>
              item?.Values?.map((value, valueIndex) =>
                value?.Title && value?.Link ? (
                  <li key={`${index}-${valueIndex}`}>
                    <a href={value?.Link} aria-label={value?.Title} rel="noopener noreferrer">
                      {value?.Title}
                    </a>
                  </li>
                ) : null
              )
            )}
          </ul>
        </section>
        <section className="social-icons">
          {footerSocialItems?.Items?.map((item) =>
            item?.Values?.map((value) =>
              value?.Link && value?.Icon && value?.AltName ? (
                <a
                  href={value?.Link}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={value?.Title}
                  key={value?.Title}>
                  <img
                    className="icon_img"
                    src={`${value?.IconMobile}` || ''}
                    alt={value?.AltName}
                  />
                </a>
              ) : null
            )
          )}
        </section>
        <section
          className="white-bottom-text"
          dangerouslySetInnerHTML={{ __html: footerText?.Items?.[0]?.Values?.[0]?.Title || '' }}
        />
      </footer>
    );
  };

  return (
    <LazyLoad offset={100} onContentVisible={() => setIsGlobalLoaded(true)}>
      <>
        {isGlobalLoaded && (
          <>
            <WithRtl lazyElement={RTL} />
            {renderByDevice()}
          </>
        )}
      </>
    </LazyLoad>
  );
}

export default Footer;
